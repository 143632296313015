let lightbox = new PhotoSwipeLightbox({
    gallery: '.pswp-gallery',
    children: 'a',
    // dynamic import is not supported in UMD version
    pswpModule: PhotoSwipe 
});
lightbox.init();

if (document.querySelector('.splide')){
    let splide = new Splide('.splide', {
        type: 'fade',
        easing: 'ease-in-out',
        speed: 1500,
        arrows: false,
        pagination: false,
        autoplay: true,
        interval: 5000,
    });
    splide.mount();
}

if(document.querySelector('#litepicker')){
    const picker = new Litepicker({
        element: document.getElementById('litepicker'),
        singleMode: false,
        tooltipText: {
          one: 'Nacht',
          other: 'Nächte'
        },
        tooltipNumber: (totalDays) => {
          return totalDays - 1;
        },
        buttonText: {"apply":"Anwenden","cancel":"Abbrechen"},
        delimiter: ' bis ',
        format: 'DD.MM.YYYY',
        lang: 'de-DE',
    });
}
